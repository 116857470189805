import React, { useState } from 'react';
import BottomBarWrapper from './bottombar.style';

const BottomBar = ({ icon, children, ...rest }) => {
  const [visible, setVisible] = useState(true);
  if (!visible) {
    return null;
  }
  return (
    <BottomBarWrapper {...rest}>
      {icon ? <span>icon</span> : <div></div>}
      {children}
      <div className='close'>
        <button type='button' onClick={() => setVisible(false)}>
          x
        </button>
      </div>
    </BottomBarWrapper>
  );
};

export default BottomBar;
