import styled, { css } from 'styled-components';

const BottomBarWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0.5rem 2.5rem;
  background-color: ${({ theme }) => theme.colors.info};
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.85rem;
  font-weight: 500;
  a {
    color: ${({ theme }) => theme.colors.yellow};
    font-weight: 700;
    text-decoration: underline;
  }
  .close {
    vertical-align: middle;
    button {
      ${'' /* background: red; */}
      background: rgba(0, 0, 0, 0.2);
      border: none;
      border-radius: 100px;
      padding: 0.25rem 0.5rem;
      display: flex;
      align-items: center;
      align-content: inherit;
      vertical-align: middle;
      color: ${({ theme }) => theme.colors.white};
      border: 1px solid rgba(0, 0, 0, 0.2);
      font-weight: 700;
      font-size: 0.85rem;
      transition: 0.35s all;
      cursor: pointer;
      &:hover {
        box-shadow: 0px 3px 8px 0px rgba(43, 83, 135, 0.08);
        border: 1px solid ${({ theme }) => theme.colors.white};
      }
    }
  }
  ${({ fixed }) =>
    fixed &&
    css`
      position: fixed;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 99;
    `}
  ${({ danger }) =>
    danger &&
    css`
      background-color: ${({ theme }) => theme.colors.danger};
      color: ${({ theme }) => theme.colors.white};
    `}
`;

export default BottomBarWrapper;
