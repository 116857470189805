const config = {
  siteTitle: 'Light-Up – Iluminación Profesional', // Site title.
  siteTitleShort: 'Light-Up', // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  siteTitleAlt: 'Light-Up', // Alternative site title for SEO.
  siteLogo:
    'https://res.cloudinary.com/chamlabs/image/upload/v1561308148/light-up-cl/logo-512x512_jszqoj.png', // '/logos/logo-512x512.png', // Logo used for SEO and manifest.
  siteAuthor: 'Diego Cortez',
  contentFolder: 'content',
  siteUrl: 'https://www.light-up.cl', // Domain of your website without pathPrefix.
  pathPrefix: '/', // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
  siteDescription:
    'Light-Up – Iluminación Escénica y Arquitectónica. Proyectos y Productos profesionales, por un equipo Profesional', // Website description used for RSS feeds/meta description tag.
  siteRss: '/rss.xml', // Path to the RSS file.
  siteKeywords:
    'iluminacion, escenico, escenarios, teatral, teatros, institucional, arquitectonica, consolas, equipos, sistemas de control, pisos danza, dance floor, equipamiento, maquinaria, rosco, escenario modular, focos, elipsos, grandma, grandma3, fresnel, frezno.',
  siteFBAppID: '', // FB Application ID for using app insights
  googleAnalyticsID: 'UA-142738945-1', // GA tracking ID.
  googleTagManagerID: 'GTM-TN2KD6Z', // google tag manager ID
  postDefaultCategoryID: 'Lighting', // Default category for posts.
  dateFromFormat: 'YYYY-MM-DD', // Date format used in the frontmatter.
  dateFormat: 'DD/MM/YYYY', // Date format for display.
  userName: 'Light-Up', // Username to display in the author segment.
  userEmail: 'contacto@light-up.cl', // Email used for RSS feed's author segment
  userTwitter: '@lightup_cl', // Optionally renders "Follow Me" in the UserInfo segment.
  userLocation: 'Santiago, Chile', // User location to display in the author segment.
  userAvatar: 'https://api.adorable.io/avatars/150/test.png', // User avatar to display in the author segment.
  userDescription: 'Light-Up – Iluminación Profesional', // User description to display in the author segment.
  // Links to social profiles/projects you want to display in the author segment/navigation bar.
  userLinks: [
    // {
    //   label: "GitHub",
    //   url: "https://github.com/Vagr9K/gatsby-advanced-starter",
    //   iconClassName: "fa fa-github"
    // },
    // {
    //   label: "Twitter",
    //   url: "https://twitter.com/Vagr9K",
    //   iconClassName: "fa fa-twitter"
    // },
    {
      label: 'Email',
      url: 'mailto:contacto@light-up.cl',
      iconClassName: 'fa fa-envelope'
    }
  ],
  copyright: 'Copyright © 2019. Fadiluk', // Copyright string for the footer of the website and RSS feed.
  themeColor: '#FF7B00', // Used for setting manifest and progress theme colors.
  backgroundColor: '#fff' // Used for setting manifest background color.
};

// Validate

// Make sure pathPrefix is empty if not needed
if (config.pathPrefix === '/') {
  config.pathPrefix = '';
} else {
  // Make sure pathPrefix only contains the first forward slash
  config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, '')}`;
}

// Make sure siteUrl doesn't have an ending forward slash
if (config.siteUrl.substr(-1) === '/') config.siteUrl = config.siteUrl.slice(0, -1);

// Make sure siteRss has a starting forward slash
if (config.siteRss && config.siteRss[0] !== '/') config.siteRss = `/${config.siteRss}`;

module.exports = config;
