import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { socialTwitter } from 'react-icons-kit/ionicons/socialTwitter';
import { socialFacebook } from 'react-icons-kit/ionicons/socialFacebook';
import { socialDribbbleOutline } from 'react-icons-kit/ionicons/socialDribbbleOutline';
import { socialGithub } from 'react-icons-kit/ionicons/socialGithub';
import { socialGoogleplusOutline } from 'react-icons-kit/ionicons/socialGoogleplusOutline';

import BottomBar from 'common/source/containers/BottomBar';

import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Image from 'reusecore/src/elements/Image';
import Heading from 'reusecore/src/elements/Heading';
// import Select from 'reusecore/src/elements/Select';
import Container from '../../components/UI/Container';
// import SocialProfile from '../SocialProfile';
import FooterWrapper, { List, ListItem } from './footer.style';
import WhatsappButton from '../../../../src/components/WhatsappButton';

// import LogoWhite from '../../assets/image/logo-white.png';
// import PlaystoreImage from '../../../assets/image/ride/footerplay.svg';

const LogoWhite =
  'https://res.cloudinary.com/chamlabs/image/upload/f_auto,q_auto/v1561308219/light-up-cl/lightup-logo-white_haaxtl.png';

export const LanguageNAMES = [
  {
    label: 'Spanish',
    value: 'es'
  },
  {
    label: 'English',
    value: 'eng'
  }
];

export const SOCIAL_PROFILES = [
  {
    icon: socialTwitter,
    url: '#'
  },
  {
    icon: socialFacebook,
    url: '#'
  },
  {
    icon: socialDribbbleOutline,
    url: '#'
  },
  {
    icon: socialGithub,
    url: '#'
  },
  {
    icon: socialGoogleplusOutline,
    url: '#'
  }
];

const Footer = ({ row, col, colOne, colTwo, titleStyle }) => {
  return (
    <Fragment>
      <FooterWrapper id='footerSection'>
        <Container noGutter mobileGutter width='1200px'>
          <Box className='row' {...row}>
            <Box {...colOne}>
              {/* <Heading content="Language" {...titleStyle} />
            <Select
              options={LanguageNAMES}
              placeholder="Spanish"
              className="Language_search_select"
              aria-label="Language_search_input"
            /> */}
              <Box className='imageWrapper'>
                <Link to='/'>
                  <Image src={LogoWhite} alt='Light-Up logo' />
                </Link>
              </Box>
            </Box>
            <Box {...colTwo}>
              <Box className='col' {...col}>
                <Heading content='Servicio' {...titleStyle} />
                <List>
                  <ListItem>
                    <Link to='/' className='ListItem'>
                      Inicio
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link to='/soluciones' className='ListItem'>
                      Soluciones
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link to='/proyectos' className='ListItem'>
                      Proyectos
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link to='/productos' className='ListItem'>
                      Productos
                    </Link>
                  </ListItem>
                </List>
              </Box>
              <Box className='col' {...col}>
                <Heading content='Nosotros' {...titleStyle} />
                <List>
                  {/**
                                  <ListItem>
                    <Link to='/quienes-somos' className='ListItem'>
                      Quiénes somos
                    </Link>
                  </ListItem>
                 */}

                  <ListItem>
                    <Link to='/politicas-de-garantia' className='ListItem'>
                      Políticas de Garantía
                    </Link>
                  </ListItem>
                </List>
              </Box>
              <Box className='col' {...col}>
                <Heading content='Información' {...titleStyle} />
                <List>
                  <ListItem>
                    <Link to='/contacto' className='ListItem'>
                      Contacto
                    </Link>
                  </ListItem>
                  {/*
                <ListItem>
                  <Link to="/blog" className="ListItem">
                    Blog & Noticias
                  </Link>
                </ListItem>
                */}
                  <ListItem>
                    <p className='ListItem'>Fono: +56 (2) 3267 0070</p>
                  </ListItem>
                  <ListItem>
                    <p className='ListItem'>Presencial: 9:30 a 14:00 hrs.</p>
                  </ListItem>
                  <ListItem>
                    <p className='ListItem'>Remoto: 15:00 a 18:00 hrs.</p>
                  </ListItem>
                </List>
              </Box>
            </Box>
          </Box>
          <Box className='row copyRight' {...row}>
            <Text
              content={(
                <span>
                  Una empresa del grupo
                  {' '}
                  <a
                    href='https://www.fadiluk.cl'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Fadiluk®
                  </a>
                  {' '}
                  - Desarrollado por
                  {' '}
                  <a
                    href='https://www.chamlabs.com'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Chamlabs ◎_⦿
                  </a>
                </span>
              )}
              className='copyRightText'
            />
          </Box>
        </Container>
      </FooterWrapper>
      <WhatsappButton
        phone='56232670070'
        text='Hola! Te contacto desde el sitio web de LightUp'
      />
      <BottomBar fixed danger>
        <div>
          Debido a la situación de COVID-19, ponemos a disposición teléfono para
          whatsapp y llamados: +56 9 8429 8582
          {' '}
          <Link to='/blog/emergencia-covid-19'>Más Información</Link>
        </div>
      </BottomBar>
    </Fragment>
  );
};

// Footer style props
Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  colOne: PropTypes.object,
  colTwo: PropTypes.object,
  titleStyle: PropTypes.object,
  textStyle: PropTypes.object
};

// Footer default style
Footer.defaultProps = {
  // Footer row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-4px',
    mr: '-4px'
  },
  // Footer col one style
  colOne: {
    width: ['100%', '30%', '33%', '33%'],
    mb: ['30px', 0],
    pl: ['0px', 0],
    pr: ['0px', '0px', 0]
  },
  // Footer col two style
  colTwo: {
    width: ['100%', '70%', '67%', '67%'],
    flexBox: true,
    flexWrap: 'wrap'
  },
  // Footer col default style
  col: {
    width: ['100%', 1 / 3, 1 / 3, 1 / 3],
    pl: [0, '15px'],
    pr: [0, '15px'],
    mb: ['30px', '30px']
  },
  // widget title default style
  titleStyle: {
    color: '#FFFFFF',
    fontSize: ['15px', '16px', '16px', '18px', '18px'],
    fontWeight: '600',
    lineHeight: '1.34',
    mb: ['15px', '18px', '18px', '20px', '30px'],
    fontFamily: 'Poppins'
  },
  // Default logo size
  // logoStyle: {
  //   width: '128px',
  //   mb: '15px'
  // },
  // widget text default style
  textStyle: {
    color: '#FFFFFF',
    fontSize: '16px',
    mb: '12px',
    fontWeight: '600',
    fontFamily: 'Poppins'
  }
};

export default Footer;
