export const hexToRgb = hex => {
  hex = hex.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return [r, g, b];
};
export const rgbToHsl = (...rgb) => {
  const r = rgb[0] / 255;
  const g = rgb[1] / 255;
  const b = rgb[2] / 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);

  // calculate 'l'
  let l = (max + min) / 2;

  // if color
  let s;
  let h;
  if (max != min) {
    const diff = max - min;
    // calculate 's'
    s = l < 0.5 ? diff / (max + min) : diff / (2 - diff);
    // calculate 'h'
    switch (max) {
      // max === r
      case r:
        h = (g - b) / diff;
        break;
      // max === g
      case g:
        h = 2 + (b - r) / diff;
        break;
      // max === b
      default:
        h = 4 + (r - g) / diff;
        break;
    }
  } else {
    // else, it's achromatic
    h = 0;
    s = 0;
  }

  l *= 100;
  s *= 100;
  h *= 60;
  if (h < 0) {
    h += 360;
  }

  return [h, s, l];
};

export const hexToHsl = hex => {
  const [r, g, b] = hexToRgb(hex);
  const [h, s, l] = rgbToHsl(r, g, b);
  return [h, s, l];
};

// string utils
export const hexToRgbString = (hex, opacity) => {
  const [r, g, b] = hexToRgb(hex);
  const rgb = `rgb(${r}, ${g}, ${b})`;
  const rgba = `rgba(${r}, ${g}, ${b}, ${opacity})`;
  return opacity ? rgba : rgb;
};
export const hexToHslString = (hex, opacity) => {
  const [r, g, b] = hexToRgb(hex);
  const [h, s, l] = rgbToHsl(r, g, b);
  const hslString = `hsl(${h}, ${s}%, ${l}%)`;
  const hslaString = `hsla(${h}, ${s}%, ${l}%, ${opacity})`;
  return opacity ? hslaString : hslString;
};
