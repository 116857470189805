import React, { Component } from 'react';
import Helmet from 'react-helmet';
import urljoin from 'url-join';
import config from '../../../data/SiteConfig';
// import favicon from '../../assets/images/favicon.png';

class SEO extends Component {
  render() {
    const {
      postNode,
      postPath,
      postSEO,
      pageNode,
      path,
      pageTitle,
      prefix,
      lang = 'es'
    } = this.props;
    let title = config.siteTitle;
    let description = config.siteDescription;
    // let image = urljoin(config.siteUrl, config.pathPrefix, config.siteLogo); // default image
    let image = config.siteLogo; // default image
    let postURL;
    // let currentURL = urljoin(config.siteUrl, config.pathPrefix);
    let currentURL = urljoin(config.siteUrl);
    // const baseURL = urljoin(config.siteUrl, config.pathPrefix);
    if (postSEO) {
      const postMeta = postNode.frontmatter;
      title = `${postMeta.title} | ${config.siteTitle}`;
      description = postMeta.description ? postMeta.description : postNode.excerpt;
      image = postMeta.cover;
      postURL = urljoin(config.siteUrl, `/${prefix}/`, postPath);
    }

    if (pageNode) {
      const pageMeta = pageNode.frontmatter;
      title = `${pageMeta.title} | ${config.siteTitle}`;
      const pageDescription = pageMeta.description;
      const pageSubtitle = pageMeta.subtitle;
      description = pageSubtitle || pageDescription || pageNode.excerpt;
      image = pageMeta.cover;
    }

    if (path) {
      currentURL = urljoin(config.siteUrl, `/${path}`);
    }
    if (pageTitle) {
      title = pageTitle;
    }

    // const schemaOrgJSONLD = [
    //   {
    //     '@context': 'http://schema.org',
    //     '@type': 'WebSite',
    //     url: baseURL,
    //     name: title,
    //     alternateName: config.siteTitleAlt ? config.siteTitleAlt : ''
    //   }
    // ];
    // if (postSEO) {
    //   schemaOrgJSONLD.push(
    //     {
    //       '@context': 'http://schema.org',
    //       '@type': 'BreadcrumbList',
    //       itemListElement: [
    //         {
    //           '@type': 'ListItem',
    //           position: 1,
    //           item: {
    //             '@id': postURL,
    //             name: title,
    //             image
    //           }
    //         }
    //       ]
    //     },
    //     {
    //       '@context': 'http://schema.org',
    //       '@type': 'BlogPosting',
    //       url: baseURL,
    //       name: title,
    //       alternateName: config.siteTitleAlt ? config.siteTitleAlt : '',
    //       headline: title,
    //       image: {
    //         '@type': 'ImageObject',
    //         url: image
    //       },
    //       description
    //     }
    //   );
    // }

    const getSchemaOrgJSONLD = ({
      isBlogPost,
      innerUrl,
      innerTitle,
      innerImage,
      innerDescription,
      datePublished,
      baseImage
    }) => {
      const schemaOrgJSONLD = [
        {
          '@context': 'http://schema.org',
          '@type': 'Organization',
          name: 'Light-Up',
          url: 'https://www.light-up.cl',
          email: 'contacto@light-up.cl',
          logo: {
            '@type': 'ImageObject',
            url: baseImage
          },
          contactPoint: [
            {
              '@type': 'ContactPoint',
              telephone: '+56-232670070',
              contactType: 'sales',
              availableLanguage: 'Spanish'
            }
          ]
          // sameAs: [
          //   'https://www.facebook.com/SolarPowerIluminacion',
          //   'https://www.instagram.com/solarpowerchile',
          //   'https://twitter.com/solarpower_cl',
          //   'https://www.linkedin.com/company/solar-power-chile/'
          // ]
        },
        {
          '@context': 'http://schema.org',
          '@type': 'WebSite',
          url: 'https://www.light-up.cl',
          name: innerTitle,
          alternateName: config.siteTitle
        }
      ];
      return isBlogPost
        ? [
            ...schemaOrgJSONLD,
            {
              '@context': 'http://schema.org',
              '@type': 'BreadcrumbList',
              itemListElement: [
                {
                  '@type': 'ListItem',
                  position: 1,
                  item: {
                    '@id': innerUrl,
                    name: innerTitle,
                    image: innerImage
                  }
                }
              ]
            },
            {
              '@context': 'http://schema.org',
              '@type': 'BlogPosting',
              url: innerUrl,
              name: innerTitle,
              alternateName: config.siteTitle,
              headline: innerTitle,
              image: {
                '@type': 'ImageObject',
                url: innerImage
              },
              description: innerDescription,
              author: {
                '@type': 'Organization',
                name: 'Light-Up'
              },
              publisher: {
                '@type': 'Organization',
                name: 'Light-Up',
                url: 'https://www.light-up.cl',
                email: 'contacto@light-up.cl',
                logo: {
                  '@type': 'ImageObject',
                  url: baseImage
                },
                contactPoint: [
                  {
                    '@type': 'ContactPoint',
                    telephone: '+56-232670070',
                    contactType: 'sales',
                    availableLanguage: 'Spanish'
                  }
                ]
              },
              mainEntityOfPage: {
                '@type': 'WebSite',
                '@id': 'https://www.light-up.cl'
              },
              datePublished,
              dateModified: datePublished
            },
            {
              '@context': 'http://schema.org',
              '@type': 'NewsArticle',
              mainEntityOfPage: {
                '@type': 'WebPage',
                '@id': 'https://www.light-up.cl'
              },
              headline: innerTitle,
              image: {
                '@type': 'ImageObject',
                url: innerImage
              },
              datePublished,
              dateModified: datePublished,
              description: innerDescription,
              author: {
                '@type': 'Organization',
                name: 'Light-Up'
              },
              publisher: {
                '@type': 'Organization',
                name: 'Light-Up',
                url: 'https://www.light-up.cl',
                email: 'contacto@light-up.cl',
                logo: {
                  '@type': 'ImageObject',
                  url: baseImage
                },
                contactPoint: [
                  {
                    '@type': 'ContactPoint',
                    telephone: '+56-232670070',
                    contactType: 'sales',
                    availableLanguage: 'Spanish'
                  }
                ]
              }
            }
          ]
        : schemaOrgJSONLD;
    };

    const schemaOrgJSONLD = getSchemaOrgJSONLD({
      isBlogPost: postSEO,
      innerUrl: postSEO ? postURL : currentURL,
      innerTitle: title,
      innerImage: image,
      innerDescription: description,
      datePublished: '2019-06-06',
      baseImage: config.siteLogo
      // baseImage: urljoin(config.siteUrl, config.pathPrefix, config.siteLogo)
    });

    return (
      <Helmet htmlAttributes={{ lang }}>
        <title>{title}</title>
        {/* General tags */}
        <meta name="title" content={title} />
        <meta name="description" content={description} />
        <meta name="image" content={image} />
        <link rel="icon" type="image/png" href="/favicon.png" />
        {/* Schema.org tags */}
        <script type="application/ld+json">{JSON.stringify(schemaOrgJSONLD)}</script>
        {/* Canonical tag, same as og:url */}
        <link rel="canonical" href={postSEO ? postURL : currentURL} />
        {/* OpenGraph tags */}
        {postSEO ? (
          <meta property="og:type" content="article" />
        ) : (
          <meta property="og:type" content="website" />
        )}
        <meta property="og:title" content={title} />
        <meta property="og:url" content={postSEO ? postURL : currentURL} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="og:image:alt" content={title} />
        <meta property="og:site_name" content="Light-Up" />
        {/* <meta property="fb:app_id" content={config.siteFBAppID ? config.siteFBAppID : ''} /> */}
        {/* <meta property="fb:profile_id" content={config.FBPageSlug ? config.FBPageSlug : ''} /> */}

        <meta property="business:contact_data:website" content={config.siteUrl} />

        {/* if postSEO */}
        {postSEO && <meta name="author" content="Light-Up" />}
        {postSEO && <meta property="article:author" content="Light-Up" />}
        {postSEO && (
          <meta property="article:publisher" content={config.FBPageURL ? config.FBPageURL : ''} />
        )}

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content={config.userTwitter ? config.userTwitter : ''} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
      </Helmet>
    );
  }
}

export default SEO;
