import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import NavbarWrapper from 'reusecore/src/elements/Navbar';
import Drawer from 'reusecore/src/elements/Drawer';
import Button from 'reusecore/src/elements/Button';
import Logo from 'reusecore/src/elements/UI/Logo';
import Box from 'reusecore/src/elements/Box';
import HamburgMenu from '../../components/HamburgMenu';
import Container from '../../components/UI/Container';
// import ScrollSpyMenu from '../../components/ScrollSpyMenu';
import { DrawerContext } from '../../contexts/DrawerContext';
// import LogoImage from '../../assets/image/logo-white.png';
// import LogoImageAlt from '../../assets/image/logo.png';
const LogoImageWhite =
  'https://res.cloudinary.com/chamlabs/image/upload/f_auto,q_auto/v1561308219/light-up-cl/lightup-logo-white_haaxtl.png';
const LogoImageAlt =
  'https://res.cloudinary.com/chamlabs/image/upload/f_auto,q_auto/v1561308218/light-up-cl/lightup-logo_jh4fxg.png';
// export const MENU_ITEMS = [
//   {
//     label: 'Home',
//     path: '#banner_section',
//     offset: '0'
//   },
//   {
//     label: 'Feature',
//     path: '#feature_section',
//     offset: '0'
//   },
//   {
//     label: 'Pricing',
//     path: '#pricing_section',
//     offset: '0'
//   },
//   {
//     label: 'Testimonial',
//     path: '#testimonial_section',
//     offset: '0'
//   },
//   {
//     label: 'FAQ',
//     path: '#faq_section',
//     offset: '0'
//   }
// ];

const Navbar = ({ navbarStyle, logoStyle, button, row, menuWrapper, background }) => {
  const { state, dispatch } = useContext(DrawerContext);

  // Toggle drawer
  const toggleHandler = () => {
    dispatch({
      type: 'TOGGLE'
    });
  };
  const backgroundColor = background ? { background } : {};

  return (
    <NavbarWrapper {...navbarStyle} className="saas_navbar" {...backgroundColor}>
      <Container>
        <Box {...row}>
          <Logo
            href="/"
            logoSrc={LogoImageWhite}
            title="Light-UP logo"
            logoStyle={logoStyle}
            className="main-logo"
          />
          <Logo
            href="/"
            logoSrc={LogoImageAlt}
            title="Light-UP logo"
            logoStyle={logoStyle}
            className="logo-alt"
          />
          <Box {...menuWrapper}>
            {/*
              <ScrollSpyMenu className="main_menu" menuItems={MENU_ITEMS} offset={-70} />
              */}
            <ul className="main_menu">
              <li>
                <Link to="/soluciones">Soluciones</Link>
              </li>
              <li>
                <Link to="/productos">Productos</Link>
              </li>
              <li>
                <Link to="/proyectos">Proyectos</Link>
              </li>
              <li>
                <Link to="/osram">Osram</Link>
              </li>
              {/*
                <li>
                  <Link to="/blog">Blog</Link>
                </li>
                */}
            </ul>
            <Link to="/contacto" className="navbar_button">
              <Button variant="hoverShadow" {...button} title="CONTACTO" />
            </Link>
            <Drawer
              width="420px"
              placement="right"
              drawerHandler={<HamburgMenu barColor="#fff" />}
              open={state.isOpen}
              toggleHandler={toggleHandler}
            >
              {/*
              <ScrollSpyMenu
                className="mobile_menu"
                menuItems={MENU_ITEMS}
                drawerClose
                offset={-100}
              />
                */}

              <ul className="mobile_menu">
                <li>
                  <Link to="/soluciones">Soluciones</Link>
                </li>
                <li>
                  <Link to="/productos">Productos</Link>
                </li>
                <li>
                  <Link to="/proyectos">Proyectos</Link>
                </li>
                <li>
                  <Link to="/osram">Osram</Link>
                </li>
                {/*
                <li>
                  <Link to="/blog">Blog</Link>
                </li>
                */}
              </ul>
              <Link to="/contacto" className="navbar_drawer_button">
                <Button variant="hoverShadow" {...button} title="CONTACTO" />
              </Link>
            </Drawer>
          </Box>
        </Box>
      </Container>
    </NavbarWrapper>
  );
};

Navbar.propTypes = {
  navbarStyle: PropTypes.object,
  logoStyle: PropTypes.object,
  button: PropTypes.object,
  row: PropTypes.object,
  menuWrapper: PropTypes.object
};

Navbar.defaultProps = {
  navbarStyle: {
    minHeight: '70px',
    display: 'block'
  },
  row: {
    flexBox: true,
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  logoStyle: {
    maxWidth: ['120px', '130px']
  },
  button: {
    type: 'button',
    fontSize: '13px',
    fontWeight: '700',
    borderRadius: '4px',
    pl: '15px',
    pr: '15px',
    colors: 'primaryBoxShadow',
    // colors: 'primaryWithBg',
    minHeight: 'auto',
    height: '40px'
  },
  menuWrapper: {
    flexBox: true,
    alignItems: 'center'
  }
};

export default Navbar;
