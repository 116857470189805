import { lightenColor, darkenColor } from './utils/lightenDarken';
import { hexToRgbString } from './utils/colorUtils';

const base = {
  black: '#000000', // 1
  white: '#ffffff', // 2
  textColor: '#424242', // 3
  blue: '#0d2c54',
  green: '#72c02c',
  primary: '#FF7B00',
  secondary: '#922b32',
  // yellow: '#d1952a', // 12
  yellow: '#fc0', // '#f5af33',
  orange: '#ff5722',
  red: '#f44336',
  purple: '#212141',
  muted: '#f2f2f2',
  lightBlue: '#1A73E8'
};

const colors = {
  ...base,
  success: base.green,
  info: base.lightBlue,
  warning: base.yellow,
  danger: base.red,
  transparent: 'transparent', // 0
  black: base.black, // 1
  white: base.white, // 2
  background: lightenColor(base.textColor, 0.72), // #f9f9f9
  headingColor: base.purple,
  textColor: base.textColor, // 3
  labelColor: '#767676', // 4
  inactiveField: base.muted, // 5
  inactiveButton: lightenColor(base.primary, 0.9), // '#F9D592', //'#b7dbdd', // 6
  inactiveIcon: '#EBEBEB', // 7
  primary: base.primary, // 8
  primaryHover: darkenColor(base.primary, 0.1),
  secondary: base.purple, // 10
  secondaryHover: darkenColor(base.purple, 0.07),
  yellow: base.yellow, // 12
  yellowHover: darkenColor(base.yellow, 0.1),
  purple: base.purple,
  link: base.lightBlue,
  borderColor: '#1b1e25', // 14
  baseBoxShadow: `0px 8px 20px -6px ${hexToRgbString(base.black, 0.5)}`,
  primaryBoxShadow: `0px 8px 20px -6px ${hexToRgbString(base.primary, 0.57)}`,
  secondaryBoxShadow: `0px 8px 20px -6px ${hexToRgbString(base.purple, 0.5)}`,
  yellowBoxShadow: `0px 8px 20px -6px ${hexToRgbString(base.yellow, 0.5)}`,
  purpleBoxShadow: `0px 8px 20px -6px ${hexToRgbString(base.purple, 0.5)}`,
  greyBoxShadow: `0px 8px 20px -6px ${hexToRgbString(base.textColor, 0.5)}`,
  osram: '#f60',
  osramGrey: '#e6e7e8'
};

export default colors;
